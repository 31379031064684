import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import LawPage from '../components/LawPage';

const LawCPPage: React.FC = () => (
  <Layout id="lawCP">
    <SEO title="CPLaw" />
    <LawPage
      law="LawConsumer"
      title="fieldLawCP"
      serviceTitle="fieldLawCPTitle"
      lawTexts={['fieldLawCPOne', 'fieldLawCPTwo', 'fieldLawCPThree', 'fieldLawCPFour']}
    />
  </Layout>
);

export default LawCPPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
